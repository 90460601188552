<template>
  <div class="home-page site-page">
    <div class="site-header fx-vb">
      <div class="logo">
        <img loading="lazy" src="@/assets/images/logo.png" alt="">
      </div>
      <!-- <div class="title">
        Clockwise International (HongKong) Limited
      </div> -->
      <ul class="nav fx-vb">
        <li class="mail" @click="jumpToMail">
          <img loading="lazy" src="@/assets/images/icon-mail.png" alt="">
        </li>
        <li class="shelf" @click="jumpToShelf">
          <img loading="lazy" src="@/assets/images/icon-shelf.png" alt="">
        </li>
        <li class="user" @click="jumpToUser">
          <img loading="lazy" src="@/assets/images/icon-user.png" alt="">
        </li>
      </ul>
    </div>
    <div class="home-con">
      <!-- <div class="rank-con">
        <div class="the-title fx-vb">
          <h2>Ranking</h2>
          <div class="the-more fx-vc" @click="jumpToRank">
            View All
          </div>
        </div>
        <ul class="tab-list fx-v">
          <li class="fx-vc" v-for="item of rankTabData" :key="item.id" :class="{cur: curRankTabId === item.id}" @click="changeRankTab(item.id)">
            {{ item.name }}
          </li>
        </ul>
        <div class="rank-list">
          <div class="list-con">
            <div class="one-book fx-vb" v-for="(item, index) of rankBookList" :key="item.id" @click="jumpToBook(item.id)">
              <div class="cover">
                <img loading="lazy" :src="item.imgUrl" alt="">
              </div>
              <div class="index" :class="{['top-'+index]: index<3}">
                {{ index+1 }}
              </div>
              <div class="detail">
                <h3>{{ item.name }}</h3>
                <div class="tag">
                  {{ item.tag }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="best-con">
        <div class="the-title fx-vb">
          <h2>Best Sellers</h2>
          <div class="the-more fx-vc" @click="jumpToBookList">View All</div>
        </div>
        <div class="best-list">
          <div
            class="one-book"
            v-for="item of bestBookList"
            :key="item.id"
            @click="jumpToBook(item.id)"
          >
            <div class="cover">
              <img loading="lazy" :src="item.avatar" :alt="item.name">
            </div>
            <div class="detail">
              <h3>{{ item.name }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="top-con">
        <div class="the-title fx-vb">
          <h2>Top Picks For U!</h2>
          <!-- <div class="the-more fx-vc" @click="jumpToBookList">
            View All
          </div> -->
        </div>
        <div class="top-list common-book-list">
          <div
            class="one-book fx-vb"
            v-for="item of topBookList"
            :key="item.id"
            @click="jumpToBook(item.id)"
          >
            <div class="cover">
              <img loading="lazy" :src="item.avatar" alt="">
            </div>
            <div class="detail">
              <h3>{{ item.name }}</h3>
              <p>{{ item.summary }}</p>
              <div class="info fx-vb">
                <div class="auther">
                  {{ item.author }}
                </div>
                <div class="score">
                  {{ item.scores / 10 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="load-more" ref="loadMore">
        <span v-if="noMore">no more</span>
        <span v-else>loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { apiCagegoryBookList, apiBookList } from "@/api";
import debounce from "lodash/debounce";

export default {
  name: "HomePage",
  data() {
    return {
      rankTabData: [
        {
          id: 1,
          name: "RECOMMEND",
        },
        {
          id: 2,
          name: "Popular",
        },
        {
          id: 3,
          name: "New",
        },
      ],
      curRankTabId: 1,
      rankBookList: [
        {
          id: 1,
          imgUrl: "/images/book-img.png",
          name: "My Stepbrother's baby My Stepbrother's baby My Stepbrother's baby",
          tag: "Romace",
        },
        {
          id: 2,
          imgUrl: "/images/book-img.png",
          name: "My Stepbrother's baby",
          tag: "Romace",
        },
        {
          id: 3,
          imgUrl: "/images/book-img.png",
          name: "My Stepbrother's baby",
          tag: "Romace",
        },
        {
          id: 4,
          imgUrl: "/images/book-img.png",
          name: "My Stepbrother's baby",
          tag: "Romace",
        },
        {
          id: 5,
          imgUrl: "/images/book-img.png",
          name: "My Stepbrother's baby",
          tag: "Romace",
        },
        {
          id: 6,
          imgUrl: "/images/book-img.png",
          name: "My Stepbrother's baby",
          tag: "Romace",
        },
        {
          id: 7,
          imgUrl: "/images/book-img.png",
          name: "My Stepbrother's baby",
          tag: "Romace",
        },
        {
          id: 8,
          imgUrl: "/images/book-img.png",
          name: "My Stepbrother's baby",
          tag: "Romace",
        },
        {
          id: 9,
          imgUrl: "/images/book-img.png",
          name: "My Stepbrother's baby",
          tag: "Romace",
        },
      ],
      bestBookList: [],
      topBookList: [],
      page: 0,
      pageSize: 10,
      noMore: false,
    };
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.isLogin,
    }),
  },
  methods: {
    changeRankTab(rankId) {
      this.curRankTabId = rankId;
    },
    jumpToMail() {
      this.$router.push({
        name: "Contact",
      }); 
    },
    jumpToShelf() {
      if (!this.isLogin) {
        this.$store.commit("setShowLogin", true);
        return;
      }
      this.$router.push({
        name: "BookShelf",
      });
    },
    jumpToBookList() {
      this.$router.push({
        name: "BookList",
        params: {
          id: 12,
        },
      });
    },
    jumpToRank() {
      this.$router.push({
        name: "BookRank",
      });
    },
    jumpToBook(id) {
      this.$router.push({
        name: "BookDetail",
        params: {
          id,
        },
      });
    },
    jumpToUser() {
      if (!this.isLogin) {
        this.$store.commit("setShowLogin", true);
        return;
      }
      this.$router.push({
        name: "ProfilePage",
      });
    },
    async getBookList() {
      this.page += 1;
      const { code, data } = await apiBookList({
        page: this.page,
        size: this.pageSize,
      });
      this.$toast.clear();
      if (code) return;
      if (!data.length || data.length < this.pageSize) {
        this.noMore = true;
      }
      const _data = data.map(item => {
        if(item.avatar.indexOf('http') === -1) {
          item.avatar = 'https://static.novellettes.com/' + item.avatar
        }
        return item;
      });
      this.topBookList.push(..._data);
    },
    addScrollEvent() {
      const scrollHandler = debounce(() => {
        const moreLoad = this.$refs.loadMore;
        if (!moreLoad || this.noMore) return;
        const top = moreLoad.getBoundingClientRect().top;
        if (top < document.body.clientHeight + 100) {
          this.getBookList();
        }
      }, 100);
      window.addEventListener("scroll", scrollHandler);
    },
    async getBestBookList() {
      const {code, data} = await apiCagegoryBookList({
        id:3,
        page:1,
        size:8
      })
      if(code) return;
      const _data = data.map(item => {
        if(item.avatar.indexOf('http') === -1) {
          item.avatar = 'https://static.novellettes.com/' + item.avatar
        }
        return item;
      });
      this.bestBookList = _data;
    },
  },
  beforeRouteLeave(to, from, next) {
    localStorage.setItem("homepagePostY", window.scrollY);
    next();
  },
  activated() {
    const posY = +(localStorage.getItem("homepagePostY") || 0);
    window.scrollTo({ top: posY });
  },
  mounted() {
    this.$toast.loading({
      duration: 0,
    });
    // this.getBookList();
    // this.getBestBookList();
    // this.addScrollEvent();
    Promise.all([this.getBookList(), this.getBestBookList()]).then(() => {
      this.$toast.clear();
      this.addScrollEvent();
    }).catch(() => {
      this.$toast.clear();
      this.addScrollEvent();
    });
    
  },
};
</script>

<style lang="less">
@import url("./index.less");
</style>
